/**
 * Sell – Bootstrap 4 e-commerce template v. 1.4.1
 * Homepage: https://themes.getbootstrap.com/product/sell-bootstrap-4-e-commerce-template/
 * Copyright 2021, Bootstrapious - https://bootstrapious.com
 */
(function ($){
	
'use strict';

$(document).ready(function (){
// $(function () {

    // ------------------------------------------------------- //
    //   Multilevel dropdowns
    // ------------------------------------------------------ //

    $(".dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();

        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        $(this).siblings().toggleClass("show");
        
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass("show");
        });

    });

    // ------------------------------------------------------- //
    //   Transparent navbar dropdowns 
    //
    //   = do not make navbar 
    //   transparent if dropdown's still open 
    //   / make transparent again when dropdown's closed
    // ------------------------------------------------------ //

    var navbar = $('.navbar'),
        navbarCollapse = $('.navbar-collapse');

    $('.navbar.bg-transparent .dropdown').on('show.bs.dropdown', function () {
        makeNavbarWhite();
    });

    $('.navbar.bg-transparent .dropdown').on('hide.bs.dropdown', function () {
        // if we're not on mobile, make navbar transparent 
        // after we close the dropdown

        if (!navbarCollapse.hasClass('show')) {
            makeNavbarTransparent();
        }
    });

    $('.navbar.bg-transparent .navbar-collapse').on('show.bs.collapse', function () {
        makeNavbarWhite();
    });


    $('.navbar.bg-transparent .navbar-collapse').on('hide.bs.collapse', function () {
        makeNavbarTransparent();
    });

    function makeNavbarWhite() {
        navbar.addClass('was-transparent');
        if (navbar.hasClass('navbar-dark')) {
            navbar.addClass('was-navbar-dark');
            navbar.removeClass('navbar-dark');
        } else {
            navbar.addClass('was-navbar-light');
        }

        navbar.removeClass('bg-transparent');

        navbar.addClass('bg-white');
        navbar.addClass('navbar-light');
    }

    function makeNavbarTransparent() {
        navbar.removeClass('bg-white');
        navbar.removeClass('navbar-light');
        navbar.removeClass('was-transparent');

        navbar.addClass('bg-transparent');
        if (navbar.hasClass('was-navbar-dark')) {
            navbar.addClass('navbar-dark');
        } else {
            navbar.addClass('navbar-light');
        }

    }


    // ------------------------------------------------------- //
    //   Open & Close Fullscreen Navbar Menu
    // ------------------------------------------------------ //

    $('.navbar-collapse-fullscreen').on('show.bs.collapse', function () {
        $(document.body).addClass('overflow-hidden')
    })
    $('.navbar-collapse-fullscreen').on('hide.bs.collapse', function () {
        $(document.body).removeClass('overflow-hidden')
    })

    // ------------------------------------------------------- //
    //   Open & Close Search Panel
    // ------------------------------------------------------ //
    $('[data-toggle="search"]').on('click', function () {
        $('.search-area-wrapper').show();
        $('.search-area-input').focus();
    });

    $('.search-area-wrapper .close-btn').on('click', function () {
        $('.search-area-wrapper').hide();
    });

//     // ------------------------------------------------------- //
//     //   Ekko Lightbox
//     // ------------------------------------------------------ //
// 
//     $(document).on('click', '[data-toggle="lightbox"]', function (event) {
//         event.preventDefault();
//         $(this).ekkoLightbox({
//             alwaysShowClose: true,
//             leftArrow: '<div class="text-left"><i class="arrow arrow-left" style="width:25px; height:25px"></i></div>',
//             rightArrow: '<div class="text-right"><i class="arrow arrow-right" style="width:25px; height:25px"></i></div>',
//         });
//     });

    // ------------------------------------------------------- //
    //   Image zoom
    // ------------------------------------------------------ //

    $('[data-toggle="zoom"]').each(function(){
        $(this).zoom({
            url: $(this).attr('data-image'),
            duration: 0
        });
    });


    // ------------------------------------------------------- //
    //   Make a sticky navbar on scrolling
    // ------------------------------------------------------ //
    $(window).scroll(function () {

        var body = $('body'),
            stickyNavbar = $('nav.navbar-sticky'),
            header = $('.header'),
            topbar = $('.top-bar');
						
				var topBarOuterHeight = 0; // JSO
				topbar.each(function() {
					topBarOuterHeight += $(this).outerHeight();
				});

        function makeItFixed(x) {
            if ($(window).scrollTop() > x) {
                stickyNavbar.addClass('fixed-top');
                if (!header.hasClass('header-absolute')) {
                    body.css('padding-top', stickyNavbar.outerHeight());
                    body.data('smooth-scroll-offset', stickyNavbar.outerHeight());
                }
            } else {
                stickyNavbar.removeClass('fixed-top');
                body.css('padding-top', '0');
            }
        }

        // if .top-bar exists, affix the navbar when we scroll past .top-bar
        // else affix it immediately
        if (stickyNavbar.length > 0 && topbar.length > 0) {
            makeItFixed(topBarOuterHeight); // JSO
        } else if (stickyNavbar.length > 0) {
            makeItFixed(0);
        }
    });


    // ------------------------------------------------------- //
    //   Increase/Decrease product amount
    // ------------------------------------------------------ //
		
    $('.btn-items-decrease').on('click', function () {
        var input = $(this).siblings('.input-items');
        // JSO...
        update_cart(input, 'data-url-decrease')
				// ...JSO
    });

    $('.btn-items-increase').on('click', function () {
        var input = $(this).siblings('.input-items');
        // JSO...
        update_cart(input, 'data-url-increase')
				// ...JSO
    });

    // ------------------------------------------------------- //
    //   Scroll to top button
    // ------------------------------------------------------ //

    $(window).on('scroll', function () {
        if ($(window).scrollTop() >= 1500) {
            $('#scrollTop').fadeIn();
        } else {
            $('#scrollTop').fadeOut();
        }
    });


    $('#scrollTop').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });

    // ------------------------------------------------------- //
    //    Colour form control 
    // ------------------------------------------------------ //

    $('.btn-colour').on('click', function (e) {
        var button = $(this);

        if (button.attr('data-allow-multiple') === undefined) {
            button.parents('.colours-wrapper').find('.btn-colour').removeClass('active');
        }

        button.toggleClass('active');
    });

    // ------------------------------------------------------- //
    //  Button-style form labels used in detail.html
    // ------------------------------------------------------ //

//     $('.detail-option-btn-label').on('click', function (e) {
// 				// JSO...
// 				if($(this).hasClass('disabled')){
// 					e.preventDefault();
// 					return;
// 				}
// 				//...JSO
// 				
//         var button = $(this);
// 
//         button.parents('.detail-option').find('.detail-option-btn-label').removeClass('active');
// 
//         button.toggleClass('active');
//     });


//     // ------------------------------------------------------- //
//     //   Circle Slider
//     // ------------------------------------------------------ //
//     var circleSlider = $('.circle-slider');
//     circleSlider.on({
//         'initialized.owl.carousel': function () {
//             // we add ..mh-full-screen to the parent section to avoid items below the carousel jumping before the carousel loads
//             circleSlider.parents('section').removeClass('mh-full-screen');
//         }
//     }).owlCarousel({
//         loop: true,
//         margin: 0,
//         smartSpeed: 500,
//         responsiveClass: true,
//         navText: ['<img src="' + basePath + 'img/prev-dark.svg" alt="" width="50">', '<img src="' + basePath + 'img/next-dark.svg" alt="" width="50">'],
//         responsive: {
//             0: {
//                 items: 1,
//                 nav: false,
//                 dots: true
//             },
//             600: {
//                 items: 1,
//                 nav: false,
//                 dots: true
//             },
//             1120: {
//                 items: 1,
//                 dots: false,
//                 nav: true
//             }
//         },
//         onRefresh: function () {
//             circleSlider.find('.item').height('');
//         },
//         onRefreshed: function () {
//             var maxHeight = 0;
//             var items = circleSlider.find('.item');
//             items.each(function () {
//                 var itemHeight = $(this).outerHeight();
//                 if (itemHeight > maxHeight) {
//                     maxHeight = itemHeight;
//                 }
//             });
//             items.height(maxHeight);
//         }
//     });
//     // ------------------------------------------------------- //
//     //   Home Slider
//     // ------------------------------------------------------ //
//     var homeSlider = $('.home-slider');
//     homeSlider.owlCarousel({
//         loop: true,
//         margin: 0,
//         smartSpeed: 500,
//         responsiveClass: true,
//         navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
//         responsive: {
//             0: {
//                 items: 1,
//                 nav: false,
//                 dots: true
//             },
//             600: {
//                 items: 1,
//                 nav: false,
//                 dots: true
//             },
//             1120: {
//                 items: 1,
//                 dots: false,
//                 nav: true
//             }
//         },
//         onRefresh: function () {
//             homeSlider.find('.item').height('');
//         },
//         onRefreshed: function () {
//             var maxHeight = 0;
//             var items = homeSlider.find('.item');
//             items.each(function () {
//                 var itemHeight = $(this).height();
//                 if (itemHeight > maxHeight) {
//                     maxHeight = itemHeight;
//                 }
//             });
//             items.height(maxHeight);
//         }
//     });
// 
//     // ------------------------------------------------------- //
//     //   Home Full Slider
//     // ------------------------------------------------------ //
// 
//     var homeFullSlider = $('.home-full-slider');
//     homeFullSlider.owlCarousel({
//         loop: true,
//         margin: 0,
//         smartSpeed: 500,
//         responsiveClass: true,
//         navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
//         responsive: {
//             0: {
//                 items: 1,
//                 nav: false,
//                 dots: true
//             },
//             600: {
//                 items: 1,
//                 nav: false,
//                 dots: true
//             },
//             1120: {
//                 items: 1,
//                 dots: false,
//                 nav: true
//             }
//         },
//         onRefresh: function () {
//             homeFullSlider.find('.item').height('');
//         },
//         onRefreshed: function () {
//             var maxHeight = 0;
//             var items = homeFullSlider.find('.item');
//             items.each(function () {
//                 var itemHeight = $(this).height();
//                 if (itemHeight > maxHeight) {
//                     maxHeight = itemHeight;
//                 }
//             });
//             items.height(maxHeight);
//         }
//     });
		$('.home-full-slider').each(function(){
			// Store background images and remove them from the DOM
			var homeFullSlider = tns({
				container: this,
				loop: true,
				items: 1,
// 				controls: false,
				controlsContainer: $(this).closest('.home-full-slider-wrapper').find('.tns-controls')[0],
// 				navContainer: $(this).closest('.slider-container').find('.tns-thumbs')[0],
// 				navAsThumbnails: true,
				mouseDrag: true,
				nav: false,
				lazyload: true,
				lazyloadSelector: '.lozad',
// 				preventScrollOnTouch: 'auto',
			});
			homeFullSlider.events.on('indexChanged', function(a){
				$('#' + a.container.id).closest('.tns-outer').siblings('.tns-nav').find('button.tns-nav-active').removeClass('tns-nav-active')
				$('#' + a.container.id).closest('.tns-outer').siblings('.tns-nav').find('button:nth-child(' + (a.displayIndex) + ')').addClass('tns-nav-active')
				
				// Sometimes lozad will not detect the new image entering the viewport if the user has scrolled a little (possibly because of this: https://github.com/ApoorvSaxena/lozad.js/issues/256)
				// so we just trigger it manually...
				var newPicture = document.querySelector('#' + a.container.id + ' .tns-slide-active .lozad-picture');
				if (newPicture) picture_observer.triggerLoad(newPicture);
			});
			$('.home-full-slider-wrapper .tns-nav > *').on('click', function(){
				homeFullSlider.goTo($(this).index())
			});
			// If we don't remove this, Safari will display an awful outline when any control within gains focus
			$(homeFullSlider.getInfo().controlsContainer).removeAttr("tabindex")
		})
// 
// 
//     // ------------------------------------------------------- //
//     //   Product Slider
//     // ------------------------------------------------------ //
//     $('.product-slider').owlCarousel({
//         loop: false,
//         margin: 0,
//         nav: false,
//         dots: true,
//         navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
//         smartSpeed: 400,
//         responsiveClass: true,
//         responsive: {
//             0: {
//                 items: 1
//             },
//             600: {
//                 items: 2
//             },
//             1000: {
//                 items: 5
//             }
//         }
//     });
    $('.product-slider').each(function(){
			var productSlider = tns({
				container: this,
        loop: true,
				rewind: true,
// 				autoWidth: true,
				slideBy: 'page',
// 				controls: false,
				controlsContainer: $(this).closest('.product-slider-wrapper').find('.tns-controls')[0],
				mouseDrag: true,
				navPosition: 'bottom',
        responsive: {
					0: {
							items: 1,
							nav: false,
					},
					600: {
							items: 3,
							nav: false,
					},
					1000: {
							items: 5,
							nav: true,
					}
        },
				lazyload: true,
				lazyloadSelector: '.lozad',
			});
			$('.product-slider-wrapper .tns-nav > *').on('click', function(){
				productSlider.goTo($(this).index())
			});
			// If we don't remove this, Safari will display an awful outline when any control within gains focus
			$(productSlider.getInfo().controlsContainer).removeAttr("tabindex")
		})

    // ------------------------------------------------------- //
    // Detail Carousel with thumbs
    // ------------------------------------------------------ //
//     $('.detail-slider').owlCarousel({
//         loop: true,
//         items: 1,
//         thumbs: true,
//         thumbsPrerendered: true,
//         responsiveClass: true,
//         responsive:{
//             0:{
//                 items: 1,
//                 dots: true,
//             },
//             768:{
//                 items: 1,
//                 dots: false
//             }
//         }
//     });
		$('.detail-slider').each(function(){
			var detailSlider = tns({
				container: this,
				loop: true,
				items: 1,
				controls: false,
				navContainer: $(this).closest('.slider-container').find('.tns-thumbs')[0],
				navAsThumbnails: true,
// 				mouseDrag: true,
				lazyload: true,
				lazyloadSelector: '.lozad',
			});
			if(!detailSlider){ // Prevent a possible error that would make the page crash
				return;
			}
			detailSlider.events.on('indexChanged', function(info, eventName){
				$('#' + info.container.id).closest('.tns-outer').siblings('.tns-nav').find('button.tns-nav-active').removeClass('tns-nav-active')
				$('#' + info.container.id).closest('.tns-outer').siblings('.tns-nav').find('button:nth-child(' + (info.displayIndex) + ')').addClass('tns-nav-active')
				
				$('#' + info.container.id).closest('.slider-container').find('.tns-thumbs').find('.detail-thumb-item').removeClass('active')
				$('#' + info.container.id).closest('.slider-container').find('.tns-thumbs').find('.detail-thumb-item:nth-child(' + (info.displayIndex) + ')').addClass('active')
			});
			$('.tns-nav > *').on('click', function(){
				detailSlider.goTo($(this).index())
			});
		})
		


//     // ------------------------------------------------------- //
//     // Detail Full Carousel
//     // ------------------------------------------------------ //
//     $('.detail-full').owlCarousel({
//         loop: true,
//         items: 1,
//         dots: true,
//         responsiveClass: false
//     });
// 
//     // ------------------------------------------------------- //
//     // Brands Slider
//     // ------------------------------------------------------ -//
// 
//     var brandsSlider = $('.brands-slider');
//     brandsSlider.owlCarousel({
//         loop: true,
//         margin: 20,
//         dots: true,
//         nav: false,
//         smartSpeed: 400,
//         responsiveClass: true,
//         responsive: {
//             0: {
//                 items: 2
//             },
//             600: {
//                 items: 3
//             },
//             1000: {
//                 items: 6,
//                 loop: false
//             }
//         },
//         onRefresh: function () {
//             brandsSlider.find('.item').height('');
//         },
//         onRefreshed: function () {
//             var maxHeight = 0;
//             var items = brandsSlider.find('.item');
//             items.each(function () {
//                 var itemHeight = $(this).height();
//                 if (itemHeight > maxHeight) {
//                     maxHeight = itemHeight;
//                 }
//             });
//             if (maxHeight > 0) {
//                 items.height(maxHeight);
//             }
//         }
//     });

    // ------------------------------------------------------- //
    //   Bootstrap tooltips
    // ------------------------------------------------------- //

    $('[data-toggle="tooltip"]').tooltip();

    // ------------------------------------------------------- //
    //   Smooth Scroll
    // ------------------------------------------------------- //

    var smoothScroll = new SmoothScroll('a[data-smooth-scroll]', {
        offset: 80
    });

    // ------------------------------------------------------- //
    //   Object Fit Images
    // ------------------------------------------------------- //    

    objectFitImages();
		
    // JSO...
		const observer = lozad(); // lazy loads elements with default selector as '.lozad'
		observer.observe();
		const iframe_observer = lozad('.lozad-iframe', {
			load: function(el) {
// console.log('loading element: ' + atob(el.getAttribute('data-iframe-src')));
				el.src = atob(el.getAttribute('data-iframe-src'));
				el.classList.add('loaded');
			}
		});
		iframe_observer.observe();
		const picture_observer = lozad('.lozad-picture', {
			load: function(el) {
// console.log('loading element: ' + el.getAttribute('class'));
				$(el).find('source').each(function(){$(this).attr('srcset', $(this).attr('data-srcset'))})
				$(el).find('img').each(function(){$(this).attr('src', $(this).attr('data-src'))})
			},
			loaded: function(el) {
// console.log('loaded element: ' + el.getAttribute('class'));
				$(el).find('img[data-big]').pan()
				// Synch closing the zoom widget with the history stack (see (**) below)
				$('.panWrapper .close').on('click', function(e){
// console.log('close: '+location.hash)
					history.replaceState(null, null, ' ');
				})
			}
		});
		picture_observer.observe();
// 		const zoomable_image_observer = lozad('.lozad-zoom', {
// 			loaded: function(el) {
// // console.log('loaded element: ' + el.getAttribute('class'));
// 				$(el).pan();
// 			}
// 		});
// 		zoomable_image_observer.observe();

		// the current open accordion will not be able to close itself (https://stackoverflow.com/a/49306356/1091231)
// 		$('.no-self-close [data-toggle="collapse"]').on('click',function(e){
// 				if ( $(this).parents('.accordion').find('.collapse.show') ){
// 						var idx = $(this).index('[data-toggle="collapse"]');
// 						if (idx == $('.collapse.show').index('.collapse')) {
// 								e.preventDefault();
// 								e.stopPropagation();
// 						}
// 				}
// 		});
		
    // ------------------------------------------------------- //
    //   Make sure accordions work as expected
    // ------------------------------------------------------- //    
		// When a large accordion tab is shown and a different tab is then selected, as the first tab collapses it may
		// drive the newly displayed tab out of the viewport. Prevent this by making sure that we scroll the new tab's
		// header into the viewport if it isn't in the viewport after the 1st tab's collapse animation is completed
		$('.accordion .collapse').on('shown.bs.collapse', function (e){
			var $entry = $(this).closest('.block').find('.block-header');
			if(!isScrolledIntoView($entry[0])){
				$('html,body').animate({
						scrollTop: $entry.offset().top - $('nav.navbar-sticky').outerHeight()
				}, 500)
			}
		});
		
    // ------------------------------------------------------- //
    //   Sort and filter functions for listings
		//   Based on https://www.sitepoint.com/user-sortable-lists-flexbox-jquery/
    // ------------------------------------------------------- //
		(function($) {
			"use strict";

			$.fn.flexboxSorting = function(options) {
				const settings = $.extend({
					sortData: {
						price: function (element_a, element_b) {
							return parseFloat(element_a.getAttribute('data-priceFrom')) - parseFloat(element_b.getAttribute('data-priceFrom'));
						},
					},
				}, options);

				const $select = this;

				$select.on('change', () => {
					const selectedOption = $(this).val();
					sortColumns($select.closest('.products-grid').find('.sortable').children(), selectedOption);
				});

				function sortColumns(elements, opt) {
					const sortby = opt.split(":")
					const sortFunction = (() => {
						if(sortby[0] in settings.sortData){
							return settings.sortData[sortby[0]]
						}else{
							// Regular ascending order
							return (a, b) => parseFloat(a.getAttribute('data-' + sortby[0])) - parseFloat(b.getAttribute('data-' + sortby[0]))
						}
					})();
					var sortArray = elements.sort(sortFunction);
					const sign = (sortby[1] == 'desc') ? '-' : '';

					for (let i = 0; i < sortArray.length; i++) {
						$(sortArray[i]).css('order', sign + i);
					}
				}

				return $select;
			};
		})(jQuery);

		$('.products-grid .custom-select').each(function(){
			$(this).flexboxSorting();
		})
		
		// Filters
		$('.products-grid + .sidebar a.nav-link').on('click', function(event){
			event.preventDefault();
			event.stopPropagation();
			
// 			$(this).toggleClass('active').blur() # extend this so that the modal's selections will be synced with the sidebar's
			$(this).closest('.sidebar').find('a.nav-link[data-filter-property="' + $(this).attr('data-filter-property') + '"][data-filter-value="' + $(this).attr('data-filter-value') + '"]').toggleClass(['active','btn-dark']).blur()
			var active_filters = {}
			$(this).closest('.sidebar').find('.modal a.nav-link.active').each(function(){
				if(!($(this).attr('data-filter-property') in active_filters)){
					active_filters[$(this).attr('data-filter-property')] = []
				}
				active_filters[$(this).attr('data-filter-property')].push($(this).attr('data-filter-value'))
			})
			var active_properties = Object.keys(active_filters)
			var shown_products = 0;
			$(this).closest('.sidebar').siblings('.products-grid').find('.sortable').children().each(function(){
				var show = true
				for (let i = 0; i < active_properties.length; i++){
					if(!active_filters[active_properties[i]].includes($(this).attr('data-' + active_properties[i]))){
						show = false
						break
					}
				}
				if(show){
					$(this).removeClass('d-none')
					shown_products = shown_products + 1
				}else{
					$(this).addClass('d-none')
				}
			})
			$(this).closest('.sidebar').siblings('.products-grid').find('#product-count').html(shown_products)
			$(this).closest('.sidebar').find('.modal').modal('hide')
		})
		
		// Textarea character limits (used on order gift messages and e-gifts)
		var e = jQuery.Event( 'keyup', { which: 16 } ); // Code for Shift key
		$('.limit-txt').keyup(function(event) {
			var maxLen = $(this).attr('maxlength');
			var Length = $(this).val().length;
			var AmountLeft = maxLen - Length;
			$(this).next().find('.remaining-characters').html(AmountLeft);
    }).trigger(e);
		
		// Review translation toggler
		function toggle_review_language(element, selector_to_show, selector_to_hide ){
			$(element).closest('.media-body').find(selector_to_show).removeClass('d-none');
			$(element).closest('.media-body').find(selector_to_hide).addClass('d-none');
		}
		$('.review-orig .review-toggler').click(function(e){
			toggle_review_language(this, '.review-trad', '.review-orig');
			e.preventDefault();			
		});
		$('.review-trad .review-toggler').click(function(e){
			toggle_review_language(this, '.review-orig', '.review-trad');
			e.preventDefault();
		});
		
		// Secondary tab activators
		$('[data-toggle="tab-secondary"]').on('click', function (e) {
			// Click the primary tab
			var tab = $('[data-toggle="tab"][href="' + $(this).attr('href') + '"]');
			$(tab).click(); // attr and not prop
			
			// Scroll the tab into the viewport
			$('html,body').animate({
				scrollTop: tab.offset().top - $('nav.navbar-sticky').outerHeight()
			}, 500)

			e.preventDefault();
    });
		
		// (**) User-friendly use of the browser navigation buttons (back & forward)
		// 1) Modals and dropdowns should push and pop state into the history stack as they show and hide
		$('.modal').on('shown.bs.modal', function(e){
// console.log('shown: '+location.hash)
			var key = '{md:'+$(this).attr('id')+'}'
			if(location.hash != '#'+key){
				location.hash = key
			}
		})
		$('.modal').on('hidden.bs.modal', function(e){
// console.log('hidden: '+location.hash)
			var matches = location.hash.match(/{([^:]+):([^}]+)}$/)
			if(Array.isArray(matches) && (matches[1] == 'md') && (matches[2] == $(this).attr('id'))){
				history.back()
			}
		})
		// 2) Zooming images should push and pop state into the history stack
		$('img[data-big]').on('click', function(e){
// console.log('shown: '+location.hash)
			var key = '{zm:zm}'
			if(location.hash != '#'+key){
				location.hash = key
			}
		})
		// 3) Popstate
		$(window).on('popstate', function(){
// console.log('pop: '+location.hash)
			var matches = null
			if(location.hash){
				matches = location.hash.match(/{([^:]+):([^}]+)}$/);
// console.log('match: '+matches[1]+':'+matches[2])
				if(Array.isArray(matches)){
					if((matches[1] == 'md') && !$('#'+matches[2]).is(':visible')){
						$('#'+matches[2]).modal('show')
					}else if((matches[1] == 'zm')){
						$('.tns-slide-active img[data-big]').click()
					}
				}
			}
			if(!Array.isArray(matches)){
				$('.modal').modal('hide')
				$('.panWrapper .close').click()
			}
		});
		// ...JSO
});

}(jQuery))